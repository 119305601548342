.msrpc-form{
  width: 100%;
}

.msrpc-form-row{
  margin-bottom: $standart_space;
}

.msrpc-form-label{
  margin-bottom: $standart_space / 2;
  display: block;
}

.msrpc-form-input{
  display: block;
  border-radius: $border_radius;
  border: 1px solid $border_color;
  padding: $standart_space / 4;
  min-width: $min_width;
}

.msrpc-form-notification{
  @extend .msrpc-form-input;
  margin: $standart_space 0;
  background: $default_bg;
  &.success_mod{
    background: $success_color;
    color: #fff;
  }
  &.error_mod{
    background: $error_color;
    color: #fff;
  }
}

.msrpc-form-button{
  @extend .msrpc-form-input;
  background: $success_color;
  color: #fff;
}

.hidden_mod{
  display: none;
}

.msrpc-error{
  display: block;
  font-size: 0.8em;
  color: $error_color;
}
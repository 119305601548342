.msrpc-promo{
  font-size: 1.5em;
}

.msrpc-promo-bold{
  font-size: 1em;
  font-weight: bold;
}

.msrpc-promo span{
  font-weight: bold;
}

.msrpc-list{
  list-style: none;
}

.msrpc-item{
  margin-bottom: 0px;
}

.msrpc-text{
  font-size: 1em;
}
